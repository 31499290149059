<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="统计报表"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-notice-bar
      left-icon="volume-o"
      color="#1989fa"
      background="#ecf9ff"
      :text="
        state.option_value == 0
          ? '报表是按订单结算时间进行统计的，今日数据有5-10分钟延迟！'
          : '报表是按订单结算时间进行统计的！'
      "
      scrollable
    />
    <van-dropdown-menu class="van-hairline--bottom" active-color="#1989fa">
      <van-dropdown-item
        v-model="state.option_value"
        :title="state.option_title"
        :options="quickOptions"
        @change="onQuickOptionChange"
        @close="onQuickOptionClose"
      />
    </van-dropdown-menu>
    <van-cell title="订单数量" :value="state.order_num ? state.order_num : 0" />
    <van-cell title="充电时长" :value="chargingTime" />
    <van-cell title="消耗电量" :value="totalPower" />
    <van-cell title="服务费用" :value="serviceFee" />
    <van-cell title="分润金额">
      <template #value>
        <span class="text-success">{{ totalProfit }}</span>
      </template>
    </van-cell>
  </div>
  <van-calendar
    v-model:show="state.showCalendar"
    type="range"
    position="right"
    color="#1989fa"
    max-range="31"
    :allow-same-day="true"
    :min-date="new Date(new Date() - 2 * 15552000000)"
    :max-date="new Date()"
    :round="false"
    @confirm="onDateRangeConfirm"
  />
</template>

<script>
import { computed, onMounted, reactive } from "vue";
import { Toast, Calendar, DropdownMenu, DropdownItem, NoticeBar } from "vant";
import { agentReport } from "@/api/report.service";
import { useStore } from "vuex";
import Moment from "moment";
export default {
  components: {
    [Calendar.name]: Calendar,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [NoticeBar.name]: NoticeBar,
  },

  setup() {
    const store = useStore();
    const agent = store.getters.agentInfo;
    const state = reactive({
      option_value: 0,
      option_title: null,
      showCalendar: false,

      // 报表数据
      order_num: 0,
      charging_time: 0,
      total_power: 0,
      total_amount: 0,
      power_fee: 0,
      service_fee: 0,
      l2_profit: 0,
    });

    const firstDay = Moment().startOf("month").format("MM/DD");
    const lastDay = Moment().endOf("month").format("MM/DD");
    const today = Moment().format("MM/DD");
    const yesterday = Moment().subtract(1, "days").format("MM/DD");
    const monday = Moment().day("Monday").format("MM/DD");
    const sunday = Moment()
      .day("Monday")
      .day(+7)
      .format("MM/DD");
    const quickOptions = [
      { text: `今天 ${today}`, value: 0 },
      { text: `昨天 ${yesterday}`, value: 1 },
      { text: `本周 ${monday} - ${sunday}`, value: 2 },
      { text: `本月 ${firstDay} - ${lastDay}`, value: 3 },
      { text: "自定义", value: 4 },
    ];

    const onQuickOptionChange = () => {
      switch (state.option_value) {
        case 0: {
          state.start_date = Moment().format("YYYY-MM-DD");
          state.end_date = state.start_date;
          break;
        }
        case 1: {
          state.start_date = Moment().subtract(1, "days").format("YYYY-MM-DD");
          state.end_date = state.start_date;
          break;
        }
        case 2: {
          state.start_date = Moment().day("Monday").format("YYYY-MM-DD");
          state.end_date = Moment()
            .day("Monday")
            .day(+7)
            .format("YYYY-MM-DD");
          break;
        }
        case 3: {
          state.start_date = Moment().startOf("month").format("YYYY-MM-DD");
          state.end_date = Moment().endOf("month").format("YYYY-MM-DD");
          break;
        }
        case 4: {
          state.start_date = null;
          state.end_date = null;
          state.showCalendar = true;
          break;
        }
        default: {
          Toast({ message: "未知的时间选项", position: "bottom" });
        }
      }
      state.option_title = null; // 置空会显示选项的text
      loadReport();
    };

    // 已是选中自定义的情况下, 再次选中自定义菜单需要手动展示日历
    // 因为此时不会触发下拉菜单的 onChange 事件
    const onQuickOptionClose = () => {
      if (state.option_value == 4) {
        state.showCalendar = true;
      } else {
        state.option_title = null;
      }
    };

    const onDateRangeConfirm = (values) => {
      const [start, end] = values;

      // 日历组件自动处理
      // const duration = Moment.duration(Moment(start).diff(Moment(end)));
      // if (Math.abs(duration.asDays()) + 1 > 31) {
      //   Toast({ message: "日期跨度不可大于31天", position: "bottom" });
      // }

      const start_date_display = Moment(start).format("YYYY/MM/DD");
      const end_date_display = Moment(end).format("YYYY/MM/DD");
      state.option_title = `${start_date_display} - ${end_date_display}`;
      quickOptions[4].text = state.option_title;
      state.start_date = Moment(start).format("YYYY-MM-DD");
      state.end_date = Moment(end).format("YYYY-MM-DD");
      state.showCalendar = false; // 提示: 这样会导致日历立即隐藏, 没有动画
      loadReport();
    };

    const loadReport = async () => {
      if (!state.start_date || !state.end_date) {
        return;
      }
      Toast.loading({ message: "加载中", duration: 0 });
      try {
        const { result } = await agentReport({
          start_date: state.start_date,
          end_date: state.end_date,
        });
        for (const key in result) {
          state[key] = result[key];
        }
        Toast.clear();
        // if (!result.order_num) {
        // }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const chargingTime = computed(() => {
      const amount = new Number(state.charging_time / 60 / 60).toFixed(2);
      return `${amount}小时`;
    });

    const totalPower = computed(() => {
      const amount = new Number(state.total_power / 100).toFixed(2);
      return `${amount}kW·h`;
    });

    const serviceFee = computed(() => {
      const amount = new Number(state.service_fee / 100).toFixed(2);
      return `${amount}元`;
    });

    const totalProfit = computed(() => {
      let profit = 0;
      if (agent.level == 1) {
        profit = state.l1_profit;
      } else if (agent.level == 2) {
        profit = state.l2_profit;
      }
      const amount = new Number(profit * 0.01).toFixed(2);
      return `${amount}元`;
    });

    onMounted(() => {
      state.option_value = 0;
      onQuickOptionChange();
    });

    return {
      state,
      quickOptions,
      onQuickOptionChange,
      onQuickOptionClose,
      onDateRangeConfirm,
      chargingTime,
      totalPower,
      serviceFee,
      totalProfit,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>